import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Popover } from '@material-ui/core'
import PropTypes from 'prop-types'
import idx from 'idx'
import { tugsSelector } from 'src/store/tugs/selectors'
import {
  pilotageTugsAddRequest,
  pilotageTugsRemoveRequest,
  pilotageTugsRequest,
} from 'src/store/pilotageTugs/actions'
import {
  TugsPopover,
  TugsPopoverItem,
  TugsPopoverNoTugsAvailable,
} from 'src/components/molecules/TugsPopover'
import { pilotageTugsSelector } from 'src/store/pilotageTugs/selectors'
import { vesselSelector } from 'src/store/vessel/selectors'
import { pilotageSelector } from 'src/store/pilotage/selectors'
import { selectedPortUuidSelector } from 'src/store/ports/selectors'
import { updateVesselRequest } from 'src/store/vessel/actions'
import BollardSwlInput from './BollardSwlInput'
import TugsAndBollards from './TugsAndBollards'
import positions, { getBollardKey } from './positions'
import Ruler from 'src/components/atoms/Ruler'
import { roundTwoDecimalPlace } from 'src/utils/formatters'

const bollardSwlReleaseDate =
  process.env.REACT_APP_BOLLARD_SWL_RELEASE &&
  new Date(process.env.REACT_APP_BOLLARD_SWL_RELEASE)

const TugsDiagramWrapper = styled(Box).attrs({ p: 2 })`
  max-width: 1000px;
  margin: auto;

  svg {
    display: block;
  }
`

const BollardPopover = styled(Popover)`
  .MuiPaper-root {
    background: none;
    box-shadow: none;
  }
`

const TugsAndBollardsContainer = styled.div`
  position: relative;
  min-width: 400px;
  max-width: 1000px;
  margin: 0px auto;
`

const TugLabelContainer = styled.div`
  position: absolute;
  width: 120px;
  height: 55px;
  z-index: 10;
  text-transform: uppercase;
  pointer-events: none;
  background: none;
  .tug-type {
    font-size: 8px;
    line-height: 15px;
    font-weight: bold;
    color: #95A6B6;
    height: 15px;
  }
  .tug-name {
    font-size: 14px;
    line-height: 15px;
    color: #fff;
    height: 15px;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tug-t {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    text-transform: lowercase;
  }
`

const TugLabel = ({ x, y, align, type, name, t }) => {

  const shortType = ({
    'Azimuth Tractor Drive': 'ATD',
    'Azimuth Stern Drive': 'ASD'
  })[type] || type

  return (
    <TugLabelContainer style={{
      top: `${y}px`,
      left: `${x}px`,
      textAlign: align
    }}>
      <div className="tug-type">{shortType}</div>
      <div className="tug-name">{name}</div>
      <div className="tug-t">{t}t</div>
    </TugLabelContainer>
  )
}

const BollardLabelContainer = styled.div`
  position: absolute;
  width: 40px;
  height: 22px;
  z-index: 10;
  text-transform: uppercase;
  .bollard-label {
    font-size: 8px;
    height: 10px;
    line-height: 10px;
    font-weight: bold;
    color: #95A6B6;
  }
  .bollard-swl {
    font-size: 10px;
    height: 10px;
    line-height: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: lowercase;
  }
`

const BollardLabel = ({ x, y, t }) => {
  return (
    <BollardLabelContainer style={{
      top: `${y}px`,
      left: `${x}px`,
      textAlign: 'center'
    }}>
      <div className="bollard-label">SWL</div>
      <div className="bollard-swl">{roundTwoDecimalPlace(Number(t))}t</div>
    </BollardLabelContainer>
  )
}

const LegacySwlLabel = styled.div`
  width: 50px;
  height: 30px;
  position: absolute;
  top: 180px;
  left: 375px;
  text-align: center;
  font-weight: bold;
  .legacy-label {
    font-size: 10px;
    line-height: 12px;
    whitespace: nowrap;
  }
  .legacy-value {
    font-size: 14px;
    color: #fff;
  }
`

const TugLinePositions = ({ readOnly }) => {

  const dispatch = useDispatch()
  const selectedPortUuid = useSelector(selectedPortUuidSelector)
  const pilotage = useSelector(pilotageSelector)
  const { data: vesselData } = useSelector(vesselSelector)
  const { data: tugs } = useSelector(tugsSelector)
  const { data: pilotageTugs, isLoading } = useSelector(pilotageTugsSelector)

  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const [editingTugPos, setEditingTugPos] = useState(null)
  const [editingBollardPos, setEditingBollardPos] = useState(null)

  const [width, setWidth] = useState(800)

  const pilotageUuid = pilotage && pilotage.data.uuid

  // old pilotages have a single vessel-level safeWorkLoad
  const pilotageCreated = pilotage && new Date(pilotage.data.createdAt)
  const legacySafeWorkLoad =
    bollardSwlReleaseDate &&
    pilotageCreated &&
    pilotageCreated.valueOf() < bollardSwlReleaseDate.valueOf() &&
    idx(vesselData, d => d.safeWorkLoad)

  const onTugClick = async (pos, event) => {
    if (isLoading || readOnly) {
      return
    }
    if (activeTugs[pos]) {
      // remove
      const success = await dispatch(
        pilotageTugsRemoveRequest(pilotageTugs[positions[pos].lineNumber].uuid)
      )
      if (success) {
        dispatch(pilotageTugsRequest(pilotageUuid))
      }
    } else {
      // show tug selector
      setPopoverAnchor(event.target)
      setEditingTugPos(pos)
    }
  }

  const onSelectTug = async function (tugUuid, pos) {
    setEditingTugPos(null)
    const success = await dispatch(
      pilotageTugsAddRequest(
        pilotageUuid,
        tugUuid,
        positions[pos].lineNumber
      )
    )
    if (success) {
      dispatch(pilotageTugsRequest(pilotageUuid))
    }
  }

  const onBollardClick = async (pos, event) => {
    if (isLoading || readOnly) {
      return
    }
    // show bollard swl input
    setPopoverAnchor(event.target)
    setEditingBollardPos(pos)
  }

  const onBollardSwlChange = async (pos, value) => {
    const key = getBollardKey(pos)
    await dispatch(
      updateVesselRequest(vesselData.IMO, {
        metadata: {
          ...vesselData.metadata,
          [key]: value
        },
        port: {
          uuid: selectedPortUuid
        }
      })
    )
  }

  const usedTugUuids = pilotageTugs
    ? Object.values(pilotageTugs).map(pt => pt.tug.uuid)
    : []

  const availableTugs = tugs
    ? tugs.filter(tug => usedTugUuids.indexOf(tug.uuid) === -1)
    : []

  const activeTugs = {}
  const activeBollards = {}
  // NOTE: key here is pos, not lineNumber as in useMasterViewData
  const warnColors = {}

  for (const pos in positions) {
    const { lineNumber } = positions[pos]
    activeTugs[pos] = tugs && pilotageTugs && pilotageTugs[lineNumber] && tugs.find(
      tug => tug.uuid === pilotageTugs[lineNumber].tug.uuid
    )
    activeBollards[pos] = vesselData && vesselData.metadata && vesselData.metadata[getBollardKey(pos)]
    let warnColor = false
    if (activeTugs[pos] && activeBollards[pos]) {
      warnColor = parseFloat(activeTugs[pos].tonnage) > parseFloat(activeBollards[pos])
    }
    warnColors[pos] = warnColor
  }

  return (
    <div style={{ margin: '-50px -30px -50px -50px' }}>

      <TugsDiagramWrapper>

        <TugsAndBollardsContainer>

          <Ruler onChange={setWidth} />

          <div
            style={{
              width: 800,
              height: 400,
              transform: `scale(${width / 800}, ${(width / 2) / 400})`,
              transformOrigin: 'top left',
              top: 0,
              left: 0,
              position: 'absolute',
              zIndex: -1
            }}>
            {
              // show legacy if present, and disabled swl controls
              legacySafeWorkLoad &&
              <LegacySwlLabel>
                <div className="legacy-label">Max SWL</div>
                <div className="legacy-value">{legacySafeWorkLoad}t</div>
              </LegacySwlLabel>
            }
            {
              // Labels and controls
              Object.keys(positions).map((pos) => {

                const { labelTopLeft, bollardLabelTopLeft, align } = positions[pos]
                const activeTug = activeTugs[pos]
                const activeBollard = activeBollards[pos]

                return (
                  <Fragment key={pos}>
                    {
                      activeTug &&
                      <TugLabel
                        x={labelTopLeft[0]}
                        y={labelTopLeft[1]}
                        align={align}
                        type={activeTug.tugType}
                        name={activeTug.name}
                        t={activeTug.tonnage}
                      />
                    }
                    {
                      activeBollard &&
                      !legacySafeWorkLoad &&
                      editingBollardPos !== pos && // don't show if currently being edited
                      <BollardLabel
                        x={bollardLabelTopLeft[0]}
                        y={bollardLabelTopLeft[1]}
                        t={activeBollard}
                      />
                    }
                  </Fragment>
                )
              })
            }
          </div>

          <TugsAndBollards
            readOnly={readOnly}
            activeTugs={activeTugs}
            warnColors={warnColors}
            onTugClick={onTugClick}
            activeBollards={
              // bollard swl controls disabled for legacy
              legacySafeWorkLoad ? activeTugs : activeBollards
            }
            onBollardClick={
              legacySafeWorkLoad ? (() => null) : onBollardClick
            }
          />

        </TugsAndBollardsContainer>

        <Box style={ { textAlign: 'center' } }>
        Note: Displayed SWL of bollards to be confirmed during MPX
        </Box>

        <Box position="relative">
          {editingTugPos && (
            <TugsPopover
              open
              anchorEl={popoverAnchor}
              onBackdropClick={() => setEditingTugPos(null)}
            >
              {availableTugs.length > 0 ? (
                availableTugs.map(tug => {
                  const { uuid, name, tonnage } = tug
                  return (
                    <TugsPopoverItem
                      key={uuid}
                      name={name}
                      tonnage={tonnage}
                      onClick={() => onSelectTug(uuid, editingTugPos)}
                    />
                  )
                })
              ) : (
                <TugsPopoverNoTugsAvailable />
              )}
            </TugsPopover>
          )}
          {
            editingBollardPos &&
            <BollardPopover
              open
              anchorEl={popoverAnchor}
              anchorOrigin={positions[editingBollardPos].bollardEditAnchorOrigin}
              transformOrigin={positions[editingBollardPos].bollardEditTransformOrigin}
              onBackdropClick={() => setEditingBollardPos(null)}
            >
              <BollardSwlInput
                value={activeBollards[editingBollardPos] || ''}
                readOnly={readOnly}
                onChange={(value) => {
                  onBollardSwlChange(editingBollardPos, value)
                  setEditingBollardPos(null)
                }}
              />
            </BollardPopover>
          }
        </Box>

      </TugsDiagramWrapper>
    </div>
  )
}

TugLinePositions.propTypes = {
  readOnly: PropTypes.bool,
}

export default TugLinePositions
