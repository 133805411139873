import * as React from "react"
import { BollardPositionName } from "./positions"

interface TugsAndBollardsProps {
    readOnly: boolean
    activeTugs: Record<BollardPositionName, boolean>
    warnColors: Record<BollardPositionName, boolean>
    activeBollards: Record<BollardPositionName, boolean>
    onTugClick(pos: BollardPositionName, event: React.MouseEvent<SVGGElement, MouseEvent>): void
    onBollardClick(pos: BollardPositionName, event: React.MouseEvent<SVGGElement, MouseEvent>): void
}

function TugsAndBollards({
    readOnly,
    activeTugs: tugs,
    warnColors,
    activeBollards: bollards,
    onTugClick,
    onBollardClick
}: TugsAndBollardsProps) {

    const getLineColor = (active: boolean, isWarn: boolean) =>
        active ? isWarn ? '#ffab4f' : '#05C985' : readOnly ? '#70889E' : '#1F8EFA' // "#39495F"

    const getStrokeColor = (active: boolean) =>
        active ? '#FFF' : readOnly ? '#70889E' : '#1F8EFA'

    const getFillColor = (active: boolean, isWarn: boolean) =>
        active ? isWarn ? '#ffab4f' : '#05C985' : '#20293C'

    const getDash = (active: boolean) =>
        active ? undefined : '1 2'

    const cursorStyle = { cursor: readOnly ? 'not-allowed' : 'pointer' }

    return (
        <svg
            width={800}
            height={400}
            viewBox="0 0 800 400"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: '100%', height: '100%' }}
        >
            <g id="TugsAndBollards">
                <g id="outline">
                    <g id="edge">
                        <mask id="path-2-inside-1" fill="white">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M647.5 199.098C647.5 224.431 558.562 256.331 558.562 256.331H314.29H284.401H232.152C226.448 256.331 220.765 255.662 215.217 254.337L156.591 240.335C153.309 239.551 150.994 236.618 150.994 233.244V162.087C150.994 158.713 153.309 155.78 156.591 154.996L215.217 140.994C220.765 139.669 226.448 139 232.152 139L284.401 139H314.29L558.562 139C558.562 139 647.5 173.766 647.5 199.098Z"
                            />
                        </mask>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M647.5 199.098C647.5 224.431 558.562 256.331 558.562 256.331H314.29H284.401H232.152C226.448 256.331 220.765 255.662 215.217 254.337L156.591 240.335C153.309 239.551 150.994 236.618 150.994 233.244V162.087C150.994 158.713 153.309 155.78 156.591 154.996L215.217 140.994C220.765 139.669 226.448 139 232.152 139L284.401 139H314.29L558.562 139C558.562 139 647.5 173.766 647.5 199.098Z"
                            stroke="white"
                            strokeWidth={4}
                            mask="url(#path-2-inside-1)"
                        />
                    </g>
                    <path
                        id="bridge_back"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M211.501 144.459V251.459L209.501 251.137V144.761L211.501 144.459Z"
                        fill="white"
                    />
                    <path
                        id="bridge_front"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M280.756 139.459V255.459H278.756V139.459H280.756Z"
                        fill="white"
                    />
                </g>
                <g id="controls">
                    <g id="bow_port">
                        <line
                            id="bow_port_line"
                            x1={608.132}
                            y1={126.575}
                            x2={592.576}
                            y2={142.132}
                            stroke={getLineColor(tugs['bow_port'] && bollards['bow_port'], warnColors['bow_port'])}
                            strokeDasharray={getDash(tugs['bow_port'] && bollards['bow_port'])}
                        />
                        <g id="bow_port_tug" style={cursorStyle} onClick={(e) => onTugClick('bow_port', e)}>
                            <g id="Vector">
                                <mask id="path-6-inside-2" fill="white">
                                    <path d="M606.849 101C603.619 101 601 103.619 601 106.849V112.697C601 115.927 603.619 118.546 606.849 118.546H621.777C623.798 118.546 625.729 117.709 627.111 116.235L629.419 113.773C631.527 111.523 631.527 108.023 629.419 105.773L627.111 103.311C625.729 101.837 623.798 101 621.777 101H606.849Z" />
                                </mask>
                                <path
                                    d="M606.849 101C603.619 101 601 103.619 601 106.849V112.697C601 115.927 603.619 118.546 606.849 118.546H621.777C623.798 118.546 625.729 117.709 627.111 116.235L629.419 113.773C631.527 111.523 631.527 108.023 629.419 105.773L627.111 103.311C625.729 101.837 623.798 101 621.777 101H606.849Z"
                                    fill={getFillColor(tugs['bow_port'], warnColors['bow_port'])}
                                    stroke={getStrokeColor(tugs['bow_port'])}
                                    strokeWidth={4}
                                    mask="url(#path-6-inside-2)"
                                />
                            </g>
                            <path
                                id="Vector_2"
                                d="M625.126 106.222C622.932 105.595 621.47 102.462 620.008 102.462H622.201H625.126L628.781 106.849C628.294 106.849 626.88 106.723 625.126 106.222ZM625.126 113.324C622.932 113.951 621.47 117.084 620.008 117.084H622.201H625.126L628.781 112.697C628.294 112.697 626.88 112.823 625.126 113.324ZM604.655 104.655C606.117 103.193 605.386 102.462 607.58 102.462H604.655L602.462 103.193V107.58C602.462 106.118 603.193 106.118 604.655 104.655ZM604.655 114.891C606.117 116.353 605.386 117.084 607.58 117.084H604.655L602.462 116.353V111.966C602.462 113.428 603.193 113.428 604.655 114.891Z"
                                fill={getStrokeColor(tugs['bow_port'])}
                            />
                        </g>
                        {
                            !tugs['bow_port'] && !bollards['bow_port'] &&
                            <g id="bow_port_mask" style={cursorStyle} onClick={(e) => onTugClick('bow_port', e)}>
                                <path
                                    id="Intersect"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M605.163 101H603.749L600 104.749V106.163L605.163 101ZM600 108.163V106.749L605.749 101H607.163L600 108.163ZM600 110.163V108.749L607.749 101H609.163L600 110.163ZM600 112.163V110.749L609.749 101H611.163L600 112.163ZM600 114.163V112.749L611.749 101H613.163L600 114.163ZM600 116.163V114.749L613.749 101H615.163L600 116.163ZM600 118.163V116.749L615.749 101H617.163L600 118.163ZM601.163 119H600V118.749L617.749 101H619.163L601.163 119ZM603.163 119H601.749L619.749 101H621.163L603.163 119ZM605.163 119H603.749L621.749 101H623.163L605.163 119ZM607.163 119H605.749L623.749 101H625.163L607.163 119ZM609.163 119H607.749L625.749 101H627.163L609.163 119ZM611.163 119H609.749L627.749 101H629.163L611.163 119ZM613.163 119H611.749L629.749 101H631V101.163L613.163 119ZM615.163 119H613.749L631 101.749V103.163L615.163 119ZM617.163 119H615.749L631 103.749V105.163L617.163 119ZM619.163 119H617.749L631 105.749V107.163L619.163 119ZM621.163 119H619.749L631 107.749V109.163L621.163 119ZM623.163 119H621.749L631 109.749V111.163L623.163 119ZM625.163 119H623.749L631 111.749V113.163L625.163 119Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('bow_port', e)}
                            id="bow_port_bollard"
                            cx={590.5}
                            cy={155.5}
                            r={7}
                            fill={getFillColor(bollards['bow_port'], warnColors['bow_port'])}
                            stroke={getStrokeColor(bollards['bow_port'])}
                            strokeDasharray={getDash(bollards['bow_port'])}
                        />
                    </g>
                    <g id="fwd_port">
                        <line
                            id="fwd_port_line"
                            x1={541.5}
                            y1={105}
                            x2={541.5}
                            y2={127}
                            stroke={getLineColor(tugs['fwd_port'] && bollards['fwd_port'], warnColors['fwd_port'])}
                            strokeDasharray={getDash(tugs['fwd_port'] && bollards['fwd_port'])}
                        />
                        <g id="fwd_port_tug" style={cursorStyle} onClick={(e) => onTugClick('fwd_port', e)}>
                            <g id="Vector_3">
                                <mask id="path-11-inside-3" fill="white">
                                    <path d="M531.849 83C528.619 83 526 85.6185 526 88.8487V94.6973C526 97.9274 528.619 100.546 531.849 100.546H546.777C548.798 100.546 550.729 99.7094 552.111 98.2349L554.419 95.7728C556.527 93.5232 556.527 90.0229 554.419 87.7732L552.111 85.3111C550.729 83.8366 548.798 83 546.777 83H531.849Z" />
                                </mask>
                                <path
                                    d="M531.849 83C528.619 83 526 85.6185 526 88.8487V94.6973C526 97.9274 528.619 100.546 531.849 100.546H546.777C548.798 100.546 550.729 99.7094 552.111 98.2349L554.419 95.7728C556.527 93.5232 556.527 90.0229 554.419 87.7732L552.111 85.3111C550.729 83.8366 548.798 83 546.777 83H531.849Z"
                                    fill={getFillColor(tugs['fwd_port'], warnColors['fwd_port'])}
                                    stroke={getStrokeColor(tugs['fwd_port'])}
                                    strokeWidth={4}
                                    mask="url(#path-11-inside-3)"
                                />
                            </g>
                            <path
                                id="Vector_4"
                                d="M550.126 88.222C547.932 87.5954 546.47 84.4622 545.008 84.4622H547.201H550.126L553.781 88.8487C553.294 88.8487 551.88 88.7233 550.126 88.222ZM550.126 95.324C547.932 95.9506 546.47 99.0838 545.008 99.0838H547.201H550.126L553.781 94.6973C553.294 94.6973 551.88 94.8227 550.126 95.324ZM529.655 86.6554C531.117 85.1932 530.386 84.4622 532.58 84.4622H529.655L527.462 85.1932V89.5797C527.462 88.1176 528.193 88.1176 529.655 86.6554ZM529.655 96.8906C531.117 98.3528 530.386 99.0838 532.58 99.0838H529.655L527.462 98.3528V93.9663C527.462 95.4284 528.193 95.4284 529.655 96.8906Z"
                                stroke={getStrokeColor(tugs['fwd_port'])}
                            />
                        </g>
                        {

                            !tugs['fwd_port'] && !bollards['fwd_port'] &&
                            <g id="fwd_port_mask" style={cursorStyle} onClick={(e) => onTugClick('fwd_port', e)}>
                                <path
                                    id="Intersect_2"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M530.163 83H528.749L525 86.7487V88.163L530.163 83ZM525 90.163V88.7487L530.749 83H532.163L525 90.163ZM525 92.163V90.7487L532.749 83H534.163L525 92.163ZM525 94.163V92.7487L534.749 83H536.163L525 94.163ZM525 96.163V94.7487L536.749 83H538.163L525 96.163ZM525 98.163V96.7487L538.749 83H540.163L525 98.163ZM525 100.163V98.7487L540.749 83H542.163L525 100.163ZM526.163 101H525V100.749L542.749 83H544.163L526.163 101ZM528.163 101H526.749L544.749 83H546.163L528.163 101ZM530.163 101H528.749L546.749 83H548.163L530.163 101ZM532.163 101H530.749L548.749 83H550.163L532.163 101ZM534.163 101H532.749L550.749 83H552.163L534.163 101ZM536.163 101H534.749L552.749 83H554.163L536.163 101ZM538.163 101H536.749L554.749 83H556V83.163L538.163 101ZM540.163 101H538.749L556 83.7487V85.163L540.163 101ZM542.163 101H540.749L556 85.7487V87.163L542.163 101ZM544.163 101H542.749L556 87.7487V89.163L544.163 101ZM546.163 101H544.749L556 89.7487V91.163L546.163 101ZM548.163 101H546.749L556 91.7487V93.163L548.163 101ZM550.163 101H548.749L556 93.7487V95.163L550.163 101Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('fwd_port', e)}
                            id="fwd_port_bollard"
                            cx={541.5}
                            cy={140.5}
                            r={7}
                            fill={getFillColor(bollards['fwd_port'], warnColors['fwd_port'])}
                            stroke={getStrokeColor(bollards['fwd_port'])}
                            strokeDasharray={getDash(bollards['fwd_port'])}
                        />
                    </g>
                    <g id="beam_port">
                        <line
                            id="beam_port_line"
                            x1={400.5}
                            y1={105}
                            x2={400.5}
                            y2={127}
                            stroke={getLineColor(tugs['beam_port'] && bollards['beam_port'], warnColors['beam_port'])}
                            strokeDasharray={getDash(tugs['beam_port'] && bollards['beam_port'])}
                        />
                        <g id="beam_port_tug" style={cursorStyle} onClick={(e) => onTugClick('beam_port', e)}>
                            <g id="Vector_5">
                                <mask id="path-16-inside-4" fill="white">
                                    <path d="M390.849 83C387.619 83 385 85.6185 385 88.8487V94.6973C385 97.9274 387.619 100.546 390.849 100.546H405.777C407.798 100.546 409.729 99.7094 411.111 98.2349L413.419 95.7728C415.527 93.5232 415.527 90.0229 413.419 87.7732L411.111 85.3111C409.729 83.8366 407.798 83 405.777 83H390.849Z" />
                                </mask>
                                <path
                                    d="M390.849 83C387.619 83 385 85.6185 385 88.8487V94.6973C385 97.9274 387.619 100.546 390.849 100.546H405.777C407.798 100.546 409.729 99.7094 411.111 98.2349L413.419 95.7728C415.527 93.5232 415.527 90.0229 413.419 87.7732L411.111 85.3111C409.729 83.8366 407.798 83 405.777 83H390.849Z"
                                    fill={getFillColor(tugs['beam_port'], warnColors['beam_port'])}
                                    stroke={getStrokeColor(tugs['beam_port'])}
                                    strokeWidth={4}
                                    mask="url(#path-16-inside-4)"
                                />
                            </g>
                            <path
                                id="Vector_6"
                                d="M409.126 88.222C406.932 87.5954 405.47 84.4622 404.008 84.4622H406.201H409.126L412.781 88.8487C412.294 88.8487 410.88 88.7233 409.126 88.222ZM409.126 95.324C406.932 95.9506 405.47 99.0838 404.008 99.0838H406.201H409.126L412.781 94.6973C412.294 94.6973 410.88 94.8227 409.126 95.324ZM388.655 86.6554C390.117 85.1932 389.386 84.4622 391.58 84.4622H388.655L386.462 85.1932V89.5797C386.462 88.1176 387.193 88.1176 388.655 86.6554ZM388.655 96.8906C390.117 98.3528 389.386 99.0838 391.58 99.0838H388.655L386.462 98.3528V93.9663C386.462 95.4284 387.193 95.4284 388.655 96.8906Z"
                                fill={getStrokeColor(tugs['beam_port'])}
                            />
                        </g>
                        {
                            !tugs['beam_port'] && !bollards['beam_port'] &&
                            <g id="beam_port_mask" style={cursorStyle} onClick={(e) => onTugClick('beam_port', e)}>
                                <path
                                    id="Intersect_3"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M389.163 83H387.749L384 86.7487V88.163L389.163 83ZM384 90.163V88.7487L389.749 83H391.163L384 90.163ZM384 92.163V90.7487L391.749 83H393.163L384 92.163ZM384 94.163V92.7487L393.749 83H395.163L384 94.163ZM384 96.163V94.7487L395.749 83H397.163L384 96.163ZM384 98.163V96.7487L397.749 83H399.163L384 98.163ZM384 100.163V98.7487L399.749 83H401.163L384 100.163ZM385.163 101H384V100.749L401.749 83H403.163L385.163 101ZM387.163 101H385.749L403.749 83H405.163L387.163 101ZM389.163 101H387.749L405.749 83H407.163L389.163 101ZM391.163 101H389.749L407.749 83H409.163L391.163 101ZM393.163 101H391.749L409.749 83H411.163L393.163 101ZM395.163 101H393.749L411.749 83H413.163L395.163 101ZM397.163 101H395.749L413.749 83H415V83.163L397.163 101ZM399.163 101H397.749L415 83.7487V85.163L399.163 101ZM401.163 101H399.749L415 85.7487V87.163L401.163 101ZM403.163 101H401.749L415 87.7487V89.163L403.163 101ZM405.163 101H403.749L415 89.7487V91.163L405.163 101ZM407.163 101H405.749L415 91.7487V93.163L407.163 101ZM409.163 101H407.749L415 93.7487V95.163L409.163 101Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('beam_port', e)}
                            id="beam_port_bollard"
                            cx={400.5}
                            cy={140.5}
                            r={7}
                            fill={getFillColor(bollards['beam_port'], warnColors['beam_port'])}
                            stroke={getStrokeColor(bollards['beam_port'])}
                            strokeDasharray={getDash(bollards['beam_port'])}
                        />
                    </g>
                    <g id="aft_port">
                        <line
                            id="aft_port_line"
                            x1={250.5}
                            y1={106}
                            x2={250.5}
                            y2={128}
                            stroke={getLineColor(tugs['aft_port'] && bollards['aft_port'], warnColors['aft_port'])}
                            strokeDasharray={getDash(tugs['aft_port'] && bollards['aft_port'])}
                        />
                        <g id="aft_port_tug" style={cursorStyle} onClick={(e) => onTugClick('aft_port', e)}>
                            <g id="Vector_7">
                                <mask id="path-21-inside-5" fill="white">
                                    <path d="M240.849 84C237.619 84 235 86.6185 235 89.8487V95.6973C235 98.9274 237.619 101.546 240.849 101.546H255.777C257.798 101.546 259.729 100.709 261.111 99.2349L263.419 96.7728C265.527 94.5232 265.527 91.0229 263.419 88.7732L261.111 86.3111C259.729 84.8366 257.798 84 255.777 84H240.849Z" />
                                </mask>
                                <path
                                    d="M240.849 84C237.619 84 235 86.6185 235 89.8487V95.6973C235 98.9274 237.619 101.546 240.849 101.546H255.777C257.798 101.546 259.729 100.709 261.111 99.2349L263.419 96.7728C265.527 94.5232 265.527 91.0229 263.419 88.7732L261.111 86.3111C259.729 84.8366 257.798 84 255.777 84H240.849Z"
                                    fill={getFillColor(tugs['aft_port'], warnColors['aft_port'])}
                                    stroke={getStrokeColor(tugs['aft_port'])}
                                    strokeWidth={4}
                                    mask="url(#path-21-inside-5)"
                                />
                            </g>
                            <path
                                id="Vector_8"
                                d="M259.126 89.222C256.932 88.5954 255.47 85.4622 254.008 85.4622H256.201H259.126L262.781 89.8487C262.294 89.8487 260.88 89.7233 259.126 89.222ZM259.126 96.324C256.932 96.9506 255.47 100.084 254.008 100.084H256.201H259.126L262.781 95.6973C262.294 95.6973 260.88 95.8227 259.126 96.324ZM238.655 87.6554C240.117 86.1932 239.386 85.4622 241.58 85.4622H238.655L236.462 86.1932V90.5797C236.462 89.1176 237.193 89.1176 238.655 87.6554ZM238.655 97.8906C240.117 99.3528 239.386 100.084 241.58 100.084H238.655L236.462 99.3528V94.9663C236.462 96.4284 237.193 96.4284 238.655 97.8906Z"
                                fill={getStrokeColor(tugs['aft_port'])}
                            />
                        </g>
                        {
                            !tugs['aft_port'] && !bollards['aft_port'] &&
                            <g id="aft_port_mask" style={cursorStyle} onClick={(e) => onTugClick('aft_port', e)}>
                                <path
                                    id="Intersect_4"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M239.163 84H237.749L234 87.7487V89.163L239.163 84ZM234 91.163V89.7487L239.749 84H241.163L234 91.163ZM234 93.163V91.7487L241.749 84H243.163L234 93.163ZM234 95.163V93.7487L243.749 84H245.163L234 95.163ZM234 97.163V95.7487L245.749 84H247.163L234 97.163ZM234 99.163V97.7487L247.749 84H249.163L234 99.163ZM234 101.163V99.7487L249.749 84H251.163L234 101.163ZM235.163 102H234V101.749L251.749 84H253.163L235.163 102ZM237.163 102H235.749L253.749 84H255.163L237.163 102ZM239.163 102H237.749L255.749 84H257.163L239.163 102ZM241.163 102H239.749L257.749 84H259.163L241.163 102ZM243.163 102H241.749L259.749 84H261.163L243.163 102ZM245.163 102H243.749L261.749 84H263.163L245.163 102ZM247.163 102H245.749L263.749 84H265V84.163L247.163 102ZM249.163 102H247.749L265 84.7487V86.163L249.163 102ZM251.163 102H249.749L265 86.7487V88.163L251.163 102ZM253.163 102H251.749L265 88.7487V90.163L253.163 102ZM255.163 102H253.749L265 90.7487V92.163L255.163 102ZM257.163 102H255.749L265 92.7487V94.163L257.163 102ZM259.163 102H257.749L265 94.7487V96.163L259.163 102Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('aft_port', e)}
                            id="aft_port_bollard"
                            cx={250.5}
                            cy={140.5}
                            r={7}
                            fill={getFillColor(bollards['aft_port'], warnColors['aft_port'])}
                            stroke={getStrokeColor(bollards['aft_port'])}
                            strokeDasharray={getDash(bollards['aft_port'])}
                        />
                    </g>
                    <g id="quarter_port">
                        <line
                            id="quarter_port_line"
                            x1={181.5}
                            y1={115}
                            x2={181.5}
                            y2={137}
                            stroke={getLineColor(tugs['quarter_port'] && bollards['quarter_port'], warnColors['quarter_port'])}
                            strokeDasharray={getDash(tugs['quarter_port'] && bollards['quarter_port'])}
                        />
                        <g id="quarter_port_tug" style={cursorStyle} onClick={(e) => onTugClick('quarter_port', e)}>
                            <g id="Vector_9">
                                <mask id="path-26-inside-6" fill="white">
                                    <path d="M171.849 92C168.619 92 166 94.6185 166 97.8487V103.697C166 106.927 168.619 109.546 171.849 109.546H186.777C188.798 109.546 190.729 108.709 192.111 107.235L194.419 104.773C196.527 102.523 196.527 99.0229 194.419 96.7732L192.111 94.3111C190.729 92.8366 188.798 92 186.777 92H171.849Z" />
                                </mask>
                                <path
                                    d="M171.849 92C168.619 92 166 94.6185 166 97.8487V103.697C166 106.927 168.619 109.546 171.849 109.546H186.777C188.798 109.546 190.729 108.709 192.111 107.235L194.419 104.773C196.527 102.523 196.527 99.0229 194.419 96.7732L192.111 94.3111C190.729 92.8366 188.798 92 186.777 92H171.849Z"
                                    fill={getFillColor(tugs['quarter_port'], warnColors['quarter_port'])}
                                    stroke={getStrokeColor(tugs['quarter_port'])}
                                    strokeWidth={4}
                                    mask="url(#path-26-inside-6)"
                                />
                            </g>
                            <path
                                id="Vector_10"
                                d="M190.126 97.222C187.932 96.5954 186.47 93.4622 185.008 93.4622H187.201H190.126L193.781 97.8487C193.294 97.8487 191.88 97.7233 190.126 97.222ZM190.126 104.324C187.932 104.951 186.47 108.084 185.008 108.084H187.201H190.126L193.781 103.697C193.294 103.697 191.88 103.823 190.126 104.324ZM169.655 95.6554C171.117 94.1932 170.386 93.4622 172.58 93.4622H169.655L167.462 94.1932V98.5797C167.462 97.1176 168.193 97.1176 169.655 95.6554ZM169.655 105.891C171.117 107.353 170.386 108.084 172.58 108.084H169.655L167.462 107.353V102.966C167.462 104.428 168.193 104.428 169.655 105.891Z"
                                fill={getStrokeColor(tugs['quarter_port'])}
                            />
                        </g>
                        {
                            !tugs['quarter_port'] && !bollards['quarter_port'] &&
                            <g id="quarter_port_mask" style={cursorStyle} onClick={(e) => onTugClick('quarter_port', e)}>
                                <path
                                    id="Intersect_5"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M170.163 92H168.749L165 95.7487V97.163L170.163 92ZM165 99.163V97.7487L170.749 92H172.163L165 99.163ZM165 101.163V99.7487L172.749 92H174.163L165 101.163ZM165 103.163V101.749L174.749 92H176.163L165 103.163ZM165 105.163V103.749L176.749 92H178.163L165 105.163ZM165 107.163V105.749L178.749 92H180.163L165 107.163ZM165 109.163V107.749L180.749 92H182.163L165 109.163ZM166.163 110H165V109.749L182.749 92H184.163L166.163 110ZM168.163 110H166.749L184.749 92H186.163L168.163 110ZM170.163 110H168.749L186.749 92H188.163L170.163 110ZM172.163 110H170.749L188.749 92H190.163L172.163 110ZM174.163 110H172.749L190.749 92H192.163L174.163 110ZM176.163 110H174.749L192.749 92H194.163L176.163 110ZM178.163 110H176.749L194.749 92H196V92.163L178.163 110ZM180.163 110H178.749L196 92.7487V94.163L180.163 110ZM182.163 110H180.749L196 94.7487V96.163L182.163 110ZM184.163 110H182.749L196 96.7487V98.163L184.163 110ZM186.163 110H184.749L196 98.7487V100.163L186.163 110ZM188.163 110H186.749L196 100.749V102.163L188.163 110ZM190.163 110H188.749L196 102.749V104.163L190.163 110Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('quarter_port', e)}
                            id="quarter_port_bollard"
                            cx={181.5}
                            cy={149.5}
                            r={7}
                            fill={getFillColor(bollards['quarter_port'], warnColors['quarter_port'])}
                            stroke={getStrokeColor(bollards['quarter_port'])}
                            strokeDasharray={getDash(bollards['quarter_port'])}
                        />
                    </g>
                    <g id="stern">
                        <line
                            id="stern_line"
                            x1={138}
                            y1={199.5}
                            x2={116}
                            y2={199.5}
                            stroke={getLineColor(tugs['stern'] && bollards['stern'], warnColors['stern'])}
                            strokeDasharray={getDash(tugs['stern'] && bollards['stern'])}
                        />
                        <g id="stern_tug" style={cursorStyle} onClick={(e) => onTugClick('stern', e)}>
                            <g id="Vector_11">
                                <mask id="path-31-inside-7" fill="white">
                                    <path d="M85.8487 191C82.6185 191 80 193.619 80 196.849V202.697C80 205.927 82.6185 208.546 85.8487 208.546H100.777C102.798 208.546 104.729 207.709 106.111 206.235L108.419 203.773C110.527 201.523 110.527 198.023 108.419 195.773L106.111 193.311C104.729 191.837 102.798 191 100.777 191H85.8487Z" />
                                </mask>
                                <path
                                    d="M85.8487 191C82.6185 191 80 193.619 80 196.849V202.697C80 205.927 82.6185 208.546 85.8487 208.546H100.777C102.798 208.546 104.729 207.709 106.111 206.235L108.419 203.773C110.527 201.523 110.527 198.023 108.419 195.773L106.111 193.311C104.729 191.837 102.798 191 100.777 191H85.8487Z"
                                    fill={getFillColor(tugs['stern'], warnColors['stern'])}
                                    stroke={getStrokeColor(tugs['stern'])}
                                    strokeWidth={4}
                                    mask="url(#path-31-inside-7)"
                                />
                            </g>
                            <path
                                id="Vector_12"
                                d="M104.126 196.222C101.932 195.595 100.47 192.462 99.0079 192.462H101.201H104.126L107.781 196.849C107.294 196.849 105.88 196.723 104.126 196.222ZM104.126 203.324C101.932 203.951 100.47 207.084 99.0079 207.084H101.201H104.126L107.781 202.697C107.294 202.697 105.88 202.823 104.126 203.324ZM83.6552 194.655C85.1173 193.193 84.3863 192.462 86.5795 192.462H83.6552L81.4619 193.193V197.58C81.4619 196.118 82.193 196.118 83.6552 194.655ZM83.6552 204.891C85.1173 206.353 84.3863 207.084 86.5795 207.084H83.6552L81.4619 206.353V201.966C81.4619 203.428 82.193 203.428 83.6552 204.891Z"
                                fill={getStrokeColor(tugs['stern'])}
                            />
                        </g>
                        {
                            !tugs['stern'] && !bollards['stern'] &&
                            <g id="stern_mask" style={cursorStyle} onClick={(e) => onTugClick('stern', e)}>
                                <path
                                    id="Intersect_6"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M84.163 191H82.7487L79 194.749V196.163L84.163 191ZM79 198.163V196.749L84.7487 191H86.163L79 198.163ZM79 200.163V198.749L86.7487 191H88.1629L79 200.163ZM79 202.163V200.749L88.7487 191H90.1629L79 202.163ZM79 204.163V202.749L90.7487 191H92.163L79 204.163ZM79 206.163V204.749L92.7487 191H94.163L79 206.163ZM79 208.163V206.749L94.7487 191H96.163L79 208.163ZM80.163 209H79V208.749L96.7487 191H98.163L80.163 209ZM82.163 209H80.7487L98.7487 191H100.163L82.163 209ZM84.163 209H82.7487L100.749 191H102.163L84.163 209ZM86.163 209H84.7487L102.749 191H104.163L86.163 209ZM88.163 209H86.7487L104.749 191H106.163L88.163 209ZM90.163 209H88.7487L106.749 191H108.163L90.163 209ZM92.163 209H90.7487L108.749 191H110V191.163L92.163 209ZM94.163 209H92.7487L110 191.749V193.163L94.163 209ZM96.163 209H94.7487L110 193.749V195.163L96.163 209ZM98.163 209H96.7487L110 195.749V197.163L98.163 209ZM100.163 209H98.7487L110 197.749V199.163L100.163 209ZM102.163 209H100.749L110 199.749V201.163L102.163 209ZM104.163 209H102.749L110 201.749V203.163L104.163 209Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('stern', e)}
                            id="stern_bollard"
                            cx={151.5}
                            cy={199.5}
                            r={7}
                            fill={getFillColor(bollards['stern'], warnColors['stern'])}
                            stroke={getStrokeColor(bollards['stern'])}
                            strokeDasharray={getDash(bollards['stern'])}
                        />
                    </g>
                    <g id="quarter_starboard">
                        <line
                            id="quarter_starboard_line"
                            x1={181.5}
                            y1={259}
                            x2={181.5}
                            y2={281}
                            stroke={getLineColor(tugs['quarter_starboard'] && bollards['quarter_starboard'], warnColors['quarter_starboard'])}
                            strokeDasharray={getDash(tugs['quarter_starboard'] && bollards['quarter_starboard'])}
                        />
                        <g id="quarter_starboard_tug" style={cursorStyle} onClick={(e) => onTugClick('quarter_starboard', e)}>
                            <g id="Vector_13">
                                <mask id="path-36-inside-8" fill="white">
                                    <path d="M171.849 287C168.619 287 166 289.619 166 292.849V298.697C166 301.927 168.619 304.546 171.849 304.546H186.777C188.798 304.546 190.729 303.709 192.111 302.235L194.419 299.773C196.527 297.523 196.527 294.023 194.419 291.773L192.111 289.311C190.729 287.837 188.798 287 186.777 287H171.849Z" />
                                </mask>
                                <path
                                    d="M171.849 287C168.619 287 166 289.619 166 292.849V298.697C166 301.927 168.619 304.546 171.849 304.546H186.777C188.798 304.546 190.729 303.709 192.111 302.235L194.419 299.773C196.527 297.523 196.527 294.023 194.419 291.773L192.111 289.311C190.729 287.837 188.798 287 186.777 287H171.849Z"
                                    fill={getFillColor(tugs['quarter_starboard'], warnColors['quarter_starboard'])}
                                    stroke={getStrokeColor(tugs['quarter_starboard'])}
                                    strokeWidth={4}
                                    mask="url(#path-36-inside-8)"
                                />
                            </g>
                            <path
                                id="Vector_14"
                                d="M190.126 292.222C187.932 291.595 186.47 288.462 185.008 288.462H187.201H190.126L193.781 292.849C193.294 292.849 191.88 292.723 190.126 292.222ZM190.126 299.324C187.932 299.951 186.47 303.084 185.008 303.084H187.201H190.126L193.781 298.697C193.294 298.697 191.88 298.823 190.126 299.324ZM169.655 290.655C171.117 289.193 170.386 288.462 172.58 288.462H169.655L167.462 289.193V293.58C167.462 292.118 168.193 292.118 169.655 290.655ZM169.655 300.891C171.117 302.353 170.386 303.084 172.58 303.084H169.655L167.462 302.353V297.966C167.462 299.428 168.193 299.428 169.655 300.891Z"
                                fill={getStrokeColor(tugs['quarter_starboard'])}
                            />
                        </g>
                        {
                            !tugs['quarter_starboard'] && !bollards['quarter_starboard'] &&
                            <g id="quarter_starboard_mask" style={cursorStyle} onClick={(e) => onTugClick('quarter_starboard', e)}>
                                <path
                                    id="Intersect_7"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M170.163 287H168.749L165 290.749V292.163L170.163 287ZM165 294.163V292.749L170.749 287H172.163L165 294.163ZM165 296.163V294.749L172.749 287H174.163L165 296.163ZM165 298.163V296.749L174.749 287H176.163L165 298.163ZM165 300.163V298.749L176.749 287H178.163L165 300.163ZM165 302.163V300.749L178.749 287H180.163L165 302.163ZM165 304.163V302.749L180.749 287H182.163L165 304.163ZM166.163 305H165V304.749L182.749 287H184.163L166.163 305ZM168.163 305H166.749L184.749 287H186.163L168.163 305ZM170.163 305H168.749L186.749 287H188.163L170.163 305ZM172.163 305H170.749L188.749 287H190.163L172.163 305ZM174.163 305H172.749L190.749 287H192.163L174.163 305ZM176.163 305H174.749L192.749 287H194.163L176.163 305ZM178.163 305H176.749L194.749 287H196V287.163L178.163 305ZM180.163 305H178.749L196 287.749V289.163L180.163 305ZM182.163 305H180.749L196 289.749V291.163L182.163 305ZM184.163 305H182.749L196 291.749V293.163L184.163 305ZM186.163 305H184.749L196 293.749V295.163L186.163 305ZM188.163 305H186.749L196 295.749V297.163L188.163 305ZM190.163 305H188.749L196 297.749V299.163L190.163 305Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('quarter_starboard', e)}
                            id="quarter_starboard_bollard"
                            cx={181.5}
                            cy={245.5}
                            r={7}
                            fill={getFillColor(bollards['quarter_starboard'], warnColors['quarter_starboard'])}
                            stroke={getStrokeColor(bollards['quarter_starboard'])}
                            strokeDasharray={getDash(bollards['quarter_starboard'])}
                        />
                    </g>
                    <g id="aft_starboard">
                        <line
                            id="aft_starboard_line"
                            x1={250.5}
                            y1={268}
                            x2={250.5}
                            y2={290}
                            stroke={getLineColor(tugs['aft_starboard'] && bollards['aft_starboard'], warnColors['aft_starboard'])}
                            strokeDasharray={getDash(tugs['aft_starboard'] && bollards['aft_starboard'])}
                        />
                        <g id="aft_starboard_tug" style={cursorStyle} onClick={(e) => onTugClick('aft_starboard', e)}>
                            <g id="Vector_15">
                                <mask id="path-41-inside-9" fill="white">
                                    <path d="M240.849 296C237.619 296 235 298.619 235 301.849V307.697C235 310.927 237.619 313.546 240.849 313.546H255.777C257.798 313.546 259.729 312.709 261.111 311.235L263.419 308.773C265.527 306.523 265.527 303.023 263.419 300.773L261.111 298.311C259.729 296.837 257.798 296 255.777 296H240.849Z" />
                                </mask>
                                <path
                                    d="M240.849 296C237.619 296 235 298.619 235 301.849V307.697C235 310.927 237.619 313.546 240.849 313.546H255.777C257.798 313.546 259.729 312.709 261.111 311.235L263.419 308.773C265.527 306.523 265.527 303.023 263.419 300.773L261.111 298.311C259.729 296.837 257.798 296 255.777 296H240.849Z"
                                    fill={getFillColor(tugs['aft_starboard'], warnColors['aft_starboard'])}
                                    stroke={getStrokeColor(tugs['aft_starboard'])}
                                    strokeWidth={4}
                                    mask="url(#path-41-inside-9)"
                                />
                            </g>
                            <path
                                id="Vector_16"
                                d="M259.126 301.222C256.932 300.595 255.47 297.462 254.008 297.462H256.201H259.126L262.781 301.849C262.294 301.849 260.88 301.723 259.126 301.222ZM259.126 308.324C256.932 308.951 255.47 312.084 254.008 312.084H256.201H259.126L262.781 307.697C262.294 307.697 260.88 307.823 259.126 308.324ZM238.655 299.655C240.117 298.193 239.386 297.462 241.58 297.462H238.655L236.462 298.193V302.58C236.462 301.118 237.193 301.118 238.655 299.655ZM238.655 309.891C240.117 311.353 239.386 312.084 241.58 312.084H238.655L236.462 311.353V306.966C236.462 308.428 237.193 308.428 238.655 309.891Z"
                                fill={getStrokeColor(tugs['aft_starboard'])}
                            />
                        </g>
                        {
                            !tugs['aft_starboard'] && !bollards['aft_starboard'] &&
                            <g id="aft_starboard_mask" style={cursorStyle} onClick={(e) => onTugClick('aft_starboard', e)}>
                                <path
                                    id="Intersect_8"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M239.163 296H237.749L234 299.749V301.163L239.163 296ZM234 303.163V301.749L239.749 296H241.163L234 303.163ZM234 305.163V303.749L241.749 296H243.163L234 305.163ZM234 307.163V305.749L243.749 296H245.163L234 307.163ZM234 309.163V307.749L245.749 296H247.163L234 309.163ZM234 311.163V309.749L247.749 296H249.163L234 311.163ZM234 313.163V311.749L249.749 296H251.163L234 313.163ZM235.163 314H234V313.749L251.749 296H253.163L235.163 314ZM237.163 314H235.749L253.749 296H255.163L237.163 314ZM239.163 314H237.749L255.749 296H257.163L239.163 314ZM241.163 314H239.749L257.749 296H259.163L241.163 314ZM243.163 314H241.749L259.749 296H261.163L243.163 314ZM245.163 314H243.749L261.749 296H263.163L245.163 314ZM247.163 314H245.749L263.749 296H265V296.163L247.163 314ZM249.163 314H247.749L265 296.749V298.163L249.163 314ZM251.163 314H249.749L265 298.749V300.163L251.163 314ZM253.163 314H251.749L265 300.749V302.163L253.163 314ZM255.163 314H253.749L265 302.749V304.163L255.163 314ZM257.163 314H255.749L265 304.749V306.163L257.163 314ZM259.163 314H257.749L265 306.749V308.163L259.163 314Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('aft_starboard', e)}
                            id="aft_starboard_bollard"
                            cx={250.5}
                            cy={254.5}
                            r={7}
                            fill={getFillColor(bollards['aft_starboard'], warnColors['aft_starboard'])}
                            stroke={getStrokeColor(bollards['aft_starboard'])}
                            strokeDasharray={getDash(bollards['aft_starboard'])}
                        />
                    </g>
                    <g id="beam_starboard">
                        <line
                            id="beam_starboard_line"
                            x1={400.5}
                            y1={268}
                            x2={400.5}
                            y2={290}
                            stroke={getLineColor(tugs['beam_starboard'] && bollards['beam_starboard'], warnColors['beam_starboard'])}
                            strokeDasharray={getDash(tugs['beam_starboard'] && bollards['beam_starboard'])}
                        />
                        <g id="beam_starboard_tug" style={cursorStyle} onClick={(e) => onTugClick('beam_starboard', e)}>
                            <g id="Vector_17">
                                <mask id="path-46-inside-10" fill="white">
                                    <path d="M390.849 296C387.619 296 385 298.619 385 301.849V307.697C385 310.927 387.619 313.546 390.849 313.546H405.777C407.798 313.546 409.729 312.709 411.111 311.235L413.419 308.773C415.527 306.523 415.527 303.023 413.419 300.773L411.111 298.311C409.729 296.837 407.798 296 405.777 296H390.849Z" />
                                </mask>
                                <path
                                    d="M390.849 296C387.619 296 385 298.619 385 301.849V307.697C385 310.927 387.619 313.546 390.849 313.546H405.777C407.798 313.546 409.729 312.709 411.111 311.235L413.419 308.773C415.527 306.523 415.527 303.023 413.419 300.773L411.111 298.311C409.729 296.837 407.798 296 405.777 296H390.849Z"
                                    fill={getFillColor(tugs['beam_starboard'], warnColors['beam_starboard'])}
                                    stroke={getStrokeColor(tugs['beam_starboard'])}
                                    strokeWidth={4}
                                    mask="url(#path-46-inside-10)"
                                />
                            </g>
                            <path
                                id="Vector_18"
                                d="M409.126 301.222C406.932 300.595 405.47 297.462 404.008 297.462H406.201H409.126L412.781 301.849C412.294 301.849 410.88 301.723 409.126 301.222ZM409.126 308.324C406.932 308.951 405.47 312.084 404.008 312.084H406.201H409.126L412.781 307.697C412.294 307.697 410.88 307.823 409.126 308.324ZM388.655 299.655C390.117 298.193 389.386 297.462 391.58 297.462H388.655L386.462 298.193V302.58C386.462 301.118 387.193 301.118 388.655 299.655ZM388.655 309.891C390.117 311.353 389.386 312.084 391.58 312.084H388.655L386.462 311.353V306.966C386.462 308.428 387.193 308.428 388.655 309.891Z"
                                fill={getStrokeColor(tugs['beam_starboard'])}
                            />
                        </g>
                        {
                            !tugs['beam_starboard'] && !bollards['beam_starboard'] &&
                            <g id="beam_starboard_mask" style={cursorStyle} onClick={(e) => onTugClick('beam_starboard', e)}>
                                <path
                                    id="Intersect_9"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M389.163 296H387.749L384 299.749V301.163L389.163 296ZM384 303.163V301.749L389.749 296H391.163L384 303.163ZM384 305.163V303.749L391.749 296H393.163L384 305.163ZM384 307.163V305.749L393.749 296H395.163L384 307.163ZM384 309.163V307.749L395.749 296H397.163L384 309.163ZM384 311.163V309.749L397.749 296H399.163L384 311.163ZM384 313.163V311.749L399.749 296H401.163L384 313.163ZM385.163 314H384V313.749L401.749 296H403.163L385.163 314ZM387.163 314H385.749L403.749 296H405.163L387.163 314ZM389.163 314H387.749L405.749 296H407.163L389.163 314ZM391.163 314H389.749L407.749 296H409.163L391.163 314ZM393.163 314H391.749L409.749 296H411.163L393.163 314ZM395.163 314H393.749L411.749 296H413.163L395.163 314ZM397.163 314H395.749L413.749 296H415V296.163L397.163 314ZM399.163 314H397.749L415 296.749V298.163L399.163 314ZM401.163 314H399.749L415 298.749V300.163L401.163 314ZM403.163 314H401.749L415 300.749V302.163L403.163 314ZM405.163 314H403.749L415 302.749V304.163L405.163 314ZM407.163 314H405.749L415 304.749V306.163L407.163 314ZM409.163 314H407.749L415 306.749V308.163L409.163 314Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('beam_starboard', e)}
                            id="beam_starboard_bollard"
                            cx={400.5}
                            cy={254.5}
                            r={7}
                            fill={getFillColor(bollards['beam_starboard'], warnColors['beam_starboard'])}
                            stroke={getStrokeColor(bollards['beam_starboard'])}
                            strokeDasharray={getDash(bollards['beam_starboard'])}
                        />
                    </g>
                    <g id="fwd_starboard">
                        <line
                            id="fwd_starboard_line"
                            x1={541.5}
                            y1={268}
                            x2={541.5}
                            y2={290}
                            stroke={getLineColor(tugs['fwd_starboard'] && bollards['fwd_starboard'], warnColors['fwd_starboard'])}
                            strokeDasharray={getDash(tugs['fwd_starboard'] && bollards['fwd_starboard'])}
                        />
                        <g id="fwd_starboard_tug" style={cursorStyle} onClick={(e) => onTugClick('fwd_starboard', e)}>
                            <g id="Vector_19">
                                <mask id="path-51-inside-11" fill="white">
                                    <path d="M531.849 296C528.619 296 526 298.619 526 301.849V307.697C526 310.927 528.619 313.546 531.849 313.546H546.777C548.798 313.546 550.729 312.709 552.111 311.235L554.419 308.773C556.527 306.523 556.527 303.023 554.419 300.773L552.111 298.311C550.729 296.837 548.798 296 546.777 296H531.849Z" />
                                </mask>
                                <path
                                    d="M531.849 296C528.619 296 526 298.619 526 301.849V307.697C526 310.927 528.619 313.546 531.849 313.546H546.777C548.798 313.546 550.729 312.709 552.111 311.235L554.419 308.773C556.527 306.523 556.527 303.023 554.419 300.773L552.111 298.311C550.729 296.837 548.798 296 546.777 296H531.849Z"
                                    fill={getFillColor(tugs['fwd_starboard'], warnColors['fwd_starboard'])}
                                    stroke={getStrokeColor(tugs['fwd_starboard'])}
                                    strokeWidth={4}
                                    mask="url(#path-51-inside-11)"
                                />
                            </g>
                            <path
                                id="Vector_20"
                                d="M550.126 301.222C547.932 300.595 546.47 297.462 545.008 297.462H547.201H550.126L553.781 301.849C553.294 301.849 551.88 301.723 550.126 301.222ZM550.126 308.324C547.932 308.951 546.47 312.084 545.008 312.084H547.201H550.126L553.781 307.697C553.294 307.697 551.88 307.823 550.126 308.324ZM529.655 299.655C531.117 298.193 530.386 297.462 532.58 297.462H529.655L527.462 298.193V302.58C527.462 301.118 528.193 301.118 529.655 299.655ZM529.655 309.891C531.117 311.353 530.386 312.084 532.58 312.084H529.655L527.462 311.353V306.966C527.462 308.428 528.193 308.428 529.655 309.891Z"
                                fill={getStrokeColor(tugs['fwd_starboard'])}
                            />
                        </g>
                        {
                            !tugs['fwd_starboard'] && !bollards['fwd_starboard'] &&
                            <g id="fwd_starboard_mask" style={cursorStyle} onClick={(e) => onTugClick('fwd_starboard', e)}>
                                <path
                                    id="Intersect_10"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M530.163 296H528.749L525 299.749V301.163L530.163 296ZM525 303.163V301.749L530.749 296H532.163L525 303.163ZM525 305.163V303.749L532.749 296H534.163L525 305.163ZM525 307.163V305.749L534.749 296H536.163L525 307.163ZM525 309.163V307.749L536.749 296H538.163L525 309.163ZM525 311.163V309.749L538.749 296H540.163L525 311.163ZM525 313.163V311.749L540.749 296H542.163L525 313.163ZM526.163 314H525V313.749L542.749 296H544.163L526.163 314ZM528.163 314H526.749L544.749 296H546.163L528.163 314ZM530.163 314H528.749L546.749 296H548.163L530.163 314ZM532.163 314H530.749L548.749 296H550.163L532.163 314ZM534.163 314H532.749L550.749 296H552.163L534.163 314ZM536.163 314H534.749L552.749 296H554.163L536.163 314ZM538.163 314H536.749L554.749 296H556V296.163L538.163 314ZM540.163 314H538.749L556 296.749V298.163L540.163 314ZM542.163 314H540.749L556 298.749V300.163L542.163 314ZM544.163 314H542.749L556 300.749V302.163L544.163 314ZM546.163 314H544.749L556 302.749V304.163L546.163 314ZM548.163 314H546.749L556 304.749V306.163L548.163 314ZM550.163 314H548.749L556 306.749V308.163L550.163 314Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('fwd_starboard', e)}
                            id="fwd_starboard_bollard"
                            cx={541.5}
                            cy={254.5}
                            r={7}
                            fill={getFillColor(bollards['fwd_starboard'], warnColors['fwd_starboard'])}
                            stroke={getStrokeColor(bollards['fwd_starboard'])}
                            strokeDasharray={getDash(bollards['fwd_starboard'])}
                        />
                    </g>
                    <g id="bow_starboard">
                        <line
                            id="bow_starboard_line"
                            x1={607.425}
                            y1={268.132}
                            x2={591.868}
                            y2={252.575}
                            stroke={getLineColor(tugs['bow_starboard'] && bollards['bow_starboard'], warnColors['bow_starboard'])}
                            strokeDasharray={getDash(tugs['bow_starboard'] && bollards['bow_starboard'])}
                        />
                        <g id="bow_starboard_tug" style={cursorStyle} onClick={(e) => onTugClick('bow_starboard', e)}>
                            <g id="Vector_21">
                                <mask id="path-56-inside-12" fill="white">
                                    <path d="M606.849 276C603.619 276 601 278.619 601 281.849V287.697C601 290.927 603.619 293.546 606.849 293.546H621.777C623.798 293.546 625.729 292.709 627.111 291.235L629.419 288.773C631.527 286.523 631.527 283.023 629.419 280.773L627.111 278.311C625.729 276.837 623.798 276 621.777 276H606.849Z" />
                                </mask>
                                <path
                                    d="M606.849 276C603.619 276 601 278.619 601 281.849V287.697C601 290.927 603.619 293.546 606.849 293.546H621.777C623.798 293.546 625.729 292.709 627.111 291.235L629.419 288.773C631.527 286.523 631.527 283.023 629.419 280.773L627.111 278.311C625.729 276.837 623.798 276 621.777 276H606.849Z"
                                    fill={getFillColor(tugs['bow_starboard'], warnColors['bow_starboard'])}
                                    stroke={getStrokeColor(tugs['bow_starboard'])}
                                    strokeWidth={4}
                                    mask="url(#path-56-inside-12)"
                                />
                            </g>
                            <path
                                id="Vector_22"
                                d="M625.126 281.222C622.932 280.595 621.47 277.462 620.008 277.462H622.201H625.126L628.781 281.849C628.294 281.849 626.88 281.723 625.126 281.222ZM625.126 288.324C622.932 288.951 621.47 292.084 620.008 292.084H622.201H625.126L628.781 287.697C628.294 287.697 626.88 287.823 625.126 288.324ZM604.655 279.655C606.117 278.193 605.386 277.462 607.58 277.462H604.655L602.462 278.193V282.58C602.462 281.118 603.193 281.118 604.655 279.655ZM604.655 289.891C606.117 291.353 605.386 292.084 607.58 292.084H604.655L602.462 291.353V286.966C602.462 288.428 603.193 288.428 604.655 289.891Z"
                                fill={getStrokeColor(tugs['bow_starboard'])}
                            />
                        </g>
                        {
                            !tugs['bow_starboard'] && !bollards['bow_starboard'] &&
                            <g id="bow_starboard_mask" style={cursorStyle} onClick={(e) => onTugClick('bow_starboard', e)}>
                                <path
                                    id="Intersect_11"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M605.163 276H603.749L600 279.749V281.163L605.163 276ZM600 283.163V281.749L605.749 276H607.163L600 283.163ZM600 285.163V283.749L607.749 276H609.163L600 285.163ZM600 287.163V285.749L609.749 276H611.163L600 287.163ZM600 289.163V287.749L611.749 276H613.163L600 289.163ZM600 291.163V289.749L613.749 276H615.163L600 291.163ZM600 293.163V291.749L615.749 276H617.163L600 293.163ZM601.163 294H600V293.749L617.749 276H619.163L601.163 294ZM603.163 294H601.749L619.749 276H621.163L603.163 294ZM605.163 294H603.749L621.749 276H623.163L605.163 294ZM607.163 294H605.749L623.749 276H625.163L607.163 294ZM609.163 294H607.749L625.749 276H627.163L609.163 294ZM611.163 294H609.749L627.749 276H629.163L611.163 294ZM613.163 294H611.749L629.749 276H631V276.163L613.163 294ZM615.163 294H613.749L631 276.749V278.163L615.163 294ZM617.163 294H615.749L631 278.749V280.163L617.163 294ZM619.163 294H617.749L631 280.749V282.163L619.163 294ZM621.163 294H619.749L631 282.749V284.163L621.163 294ZM623.163 294H621.749L631 284.749V286.163L623.163 294ZM625.163 294H623.749L631 286.749V288.163L625.163 294Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('bow_starboard', e)}
                            id="bow_starboard_bollard"
                            cx={590.5}
                            cy={240.5}
                            r={7}
                            fill={getFillColor(bollards['bow_starboard'], warnColors['bow_starboard'])}
                            stroke={getStrokeColor(bollards['bow_starboard'])}
                            strokeDasharray={getDash(bollards['bow_starboard'])}
                        />
                    </g>
                    <g id="bow">
                        <line
                            id="bow_line"
                            x1={681}
                            y1={199.5}
                            x2={659}
                            y2={199.5}
                            stroke={getLineColor(tugs['bow'] && bollards['bow'], warnColors['bow'])}
                            strokeDasharray={getDash(tugs['bow'] && bollards['bow'])}
                        />
                        <g id="bow_tug" style={cursorStyle} onClick={(e) => onTugClick('bow', e)}>
                            <g id="Vector_23">
                                <mask id="path-61-inside-13" fill="white">
                                    <path d="M692.849 191C689.619 191 687 193.619 687 196.849V202.697C687 205.927 689.619 208.546 692.849 208.546H707.777C709.798 208.546 711.729 207.709 713.111 206.235L715.419 203.773C717.527 201.523 717.527 198.023 715.419 195.773L713.111 193.311C711.729 191.837 709.798 191 707.777 191H692.849Z" />
                                </mask>
                                <path
                                    d="M692.849 191C689.619 191 687 193.619 687 196.849V202.697C687 205.927 689.619 208.546 692.849 208.546H707.777C709.798 208.546 711.729 207.709 713.111 206.235L715.419 203.773C717.527 201.523 717.527 198.023 715.419 195.773L713.111 193.311C711.729 191.837 709.798 191 707.777 191H692.849Z"
                                    fill={getFillColor(tugs['bow'], warnColors['bow'])}
                                    stroke={getStrokeColor(tugs['bow'])}
                                    strokeWidth={4}
                                    mask="url(#path-61-inside-13)"
                                />
                            </g>
                            <path
                                id="Vector_24"
                                d="M711.126 196.222C708.932 195.595 707.47 192.462 706.008 192.462H708.201H711.126L714.781 196.849C714.294 196.849 712.88 196.723 711.126 196.222ZM711.126 203.324C708.932 203.951 707.47 207.084 706.008 207.084H708.201H711.126L714.781 202.697C714.294 202.697 712.88 202.823 711.126 203.324ZM690.655 194.655C692.117 193.193 691.386 192.462 693.58 192.462H690.655L688.462 193.193V197.58C688.462 196.118 689.193 196.118 690.655 194.655ZM690.655 204.891C692.117 206.353 691.386 207.084 693.58 207.084H690.655L688.462 206.353V201.966C688.462 203.428 689.193 203.428 690.655 204.891Z"
                                fill={getStrokeColor(tugs['bow'])}
                            />
                        </g>
                        {
                            !tugs['bow'] && !bollards['bow'] &&
                            <g id="bow_mask" style={cursorStyle} onClick={(e) => onTugClick('bow', e)}>
                                <path
                                    id="Intersect_12"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M691.163 191H689.749L686 194.749V196.163L691.163 191ZM686 198.163V196.749L691.749 191H693.163L686 198.163ZM686 200.163V198.749L693.749 191H695.163L686 200.163ZM686 202.163V200.749L695.749 191H697.163L686 202.163ZM686 204.163V202.749L697.749 191H699.163L686 204.163ZM686 206.163V204.749L699.749 191H701.163L686 206.163ZM686 208.163V206.749L701.749 191H703.163L686 208.163ZM687.163 209H686V208.749L703.749 191H705.163L687.163 209ZM689.163 209H687.749L705.749 191H707.163L689.163 209ZM691.163 209H689.749L707.749 191H709.163L691.163 209ZM693.163 209H691.749L709.749 191H711.163L693.163 209ZM695.163 209H693.749L711.749 191H713.163L695.163 209ZM697.163 209H695.749L713.749 191H715.163L697.163 209ZM699.163 209H697.749L715.749 191H717V191.163L699.163 209ZM701.163 209H699.749L717 191.749V193.163L701.163 209ZM703.163 209H701.749L717 193.749V195.163L703.163 209ZM705.163 209H703.749L717 195.749V197.163L705.163 209ZM707.163 209H705.749L717 197.749V199.163L707.163 209ZM709.163 209H707.749L717 199.749V201.163L709.163 209ZM711.163 209H709.749L717 201.749V203.163L711.163 209Z"
                                    fill="#20293C"
                                />
                            </g>
                        }
                        <circle
                            style={cursorStyle} onClick={(e) => onBollardClick('bow', e)}
                            id="bow_bollard"
                            cx={646.5}
                            cy={199.5}
                            r={7}
                            fill={getFillColor(bollards['bow'], warnColors['bow'])}
                            stroke={getStrokeColor(bollards['bow'])}
                            strokeDasharray={getDash(bollards['bow'])}
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default TugsAndBollards
