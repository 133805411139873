
export interface Weather {
    id: number;
    uuid: string;
    hourly: Array<Record<string, any>>;
    tide_data: string;
    mintempC: number;
    mintempF: number;
    maxtempC: number;
    maxtempF: number;
    date: Date;
    location: {
        latitude: number;
        longitude: number;
        elevation: number;
    };
}

export enum APITypeEnum {
    STORMGEO = "Storm Geo",
    OMC = "OMC",
    ACCU_WEATHER = "Accu Weather",
    DEFAULT = "World Weather Online",
    MET_OCEAN = "Met Ocean",
    MET_OCEAN_V2 = "Met Ocean V2",
    WEATHERZONE = "Weatherzone",
    PORT_QUEST = "Port Quest",
    NONE = "None",
}

export interface WeatherWrapper {
    source: APITypeEnum
    weatherData?: Weather[]
    error?: string
}

export type WeatherDataFormatted = Record<string, any> // @note - add stronger typing

export interface WeatherAtLocationState {
    data: WeatherDataFormatted
    isLoading: boolean
    error?: string
    message?: string
}